import { capitalizeFirstLetter, IconRender } from "app/components/Table";
import Table from "app/components/Table";
import { APIS } from "Constants";
import useFetchData from "app/hooks/useFetchData ";
import EditAgent from "./components/CreateUpdate";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { PageTitle, useLayout } from "_metronic/layout/core";
import { useEffect } from "react";
import { RootState } from "setup";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/solid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const columns = (iconClickHandler: any) => [

    {
        name: "Consultants Name",
        key: "fname",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => capitalizeFirstLetter(value),
    }, {
        name: "Email",
        key: "email",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value,
    }, {
        name: "Properties",
        key: "propertiesCount",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value || 0,
    }, {
        name: "Landlords",
        key: "landlordCount",
        numaric: false,
        isSortable: true,
        renderContent: (value: any) => value || 0,
    }, {
        name: "Suspend Account",
        key: "action",
        component: (row: any) => (
            <>
                <select
                    className="form-select form-select-solid cursor-pointer"
                    aria-label="Select example"
                    onChange={(event) => iconClickHandler(row, event.target.value)}
                >
                    <option selected disabled>Select</option>
                    <option value="suspend" className=" cursor-pointer">Suspend</option>
                </select>
            </>
        ),
    }, {
        name: "View Details",
        key: "action",
        numaric: false,
        isSortable: true,
        component: (row: any) => (
            <>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Option"
                    >
                        <MenuItem value='Properties'><Link to={`/propertiesOfTheLandlord/${row?._id}`} className="" style={{ color: '#263c67' }}> Properties</Link></MenuItem>
                        <MenuItem value='Landlords'><Link to={`/LandlordsOfConsultant/${row?._id}`} className="" style={{ color: '#263c67' }}> Landlords</Link></MenuItem>
                    </Select>
                </FormControl>
            </>
        )
    }, {
        name: "Action",
        key: "action",
        component: (row: any) => (
            <>
                {IconRender(ModeEditOutlineOutlinedIcon, iconClickHandler, row, "EDIT")}
                {/* {IconRender(DeleteForeverOutlinedIcon, iconClickHandler, row.consultant, "delete")} */}
            </>
        ),
    },
];

export function AllConsultants() {
    const { create, update, delete: deleteUrl, getAllConsultants } = APIS.users;
    const {
        consultantsData,
        loading,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count,
        open,
        setOpen,
        modalMode,
        updateConsultantFunction,
        createConsultantFunction,
        createConsultantFunctionNew,
        current,
        iconClickHandler,
        setCurrent,
    } = useFetchData({ getAllConsultants, create, update, deleteUrl });
    const { setClickButtonHandler } = useLayout();

    useEffect(() => {
        setClickButtonHandler(() => () => {
            setOpen(true);
            setCurrent({});
        });
    }, []);

    const user: any = useSelector<RootState>(
        ({ auth }) => auth.user,
        shallowEqual
    );
    // Filter data to only show consultant's Landlords
    const filteredData = consultantsData?.filter((row: any) => row?.isVerified === true);

    return (
        <>
            <PageTitle>Approved Consultants</PageTitle>
            <div className="pb-5">
                <Table
                    columns={columns(iconClickHandler("/consultants"))}
                    data={filteredData}
                    loading={loading}
                    onRowClick={(row: any, type: any) =>
                        iconClickHandler("/consultants")(row, type)
                    }
                    isPaginationRequired
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    total={count}
                />
            </div>

            <EditAgent
                setOpen={setOpen}
                open={open}
                loading={loading}
                update={modalMode === "EDIT" ? updateConsultantFunction : createConsultantFunctionNew}
                type={modalMode}
                data={current}
            />

        </>
    );
}
