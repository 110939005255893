import pinContext from './pinContext'
import { useEffect, useState } from 'react'
import axios from 'axios'
// import { API as apiHost } from "../../utils/config"
const PinProvider = (props) => {
  const [mydata, setData] = useState({})
  const [alldata, setallData] = useState([])
  const [LatLong, setLatLong] = useState({
    lat: "",
    long: "",
  })
  const getData = async () => {
    // API Call

    // const response = await axios.get('https://localhost:5000/api/v1/site')
    const response = await axios.get('https://fsbo.apiimlaak.com')
    setData(response.data.data)
  }
  const detailData = async () => {
    // API Call

    // const response = await axios.get('https://localhost:5000/api/v1/site/alldata')
    const response = await axios.get('https://fsbo.apiimlaak.com/alldata')
    setallData(response.data.data.allData)
  }

  return (
    <pinContext.Provider
      value={{
        getData,
        detailData,
        mydata,
        alldata,
        setLatLong,
        LatLong

      }}
    >
      {props.children}
    </pinContext.Provider>
  )
}
export default PinProvider
