import { useGetUtilMethod, usePostUtilMethod } from "app/hooks/useAxios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import nodemailer from 'nodemailer';
import Swal from "sweetalert2";
import axios from 'axios';
import { API as apiHost } from "app/utils/config";
import { LandlordsReview, REGISTRATION_URL } from "app/constants/api";
interface IuseFetchData {
  create?: any;
  createReview?: any;
  createLandlord?: any;
  update?: any;
  updateReview?: any;
  deleteUrl?: any;
  getById?: any;
  getMyLandlords?: any;
  getAll?: any;
  getuniqueDataLocations?: any;
  getAllProject?: any;
  getAllNames?: any;
  getAllInventory?: any;
  getAllAmenities?: any;
  deleteConsultantId?: any;
  LandlordApprovalRequest?: any;
  resendWelcomeEmail?: any;
  getAllConsultants?: any,
  getConsultantDetails?: any,
  landlordReviews?: any,
  getAllRequests?: any,
  updateChangeRequest?: any,
}

const useFetchData = ({
  getAll,
  createLandlord,
  create,
  createReview,
  getAllProject,
  update,
  updateReview,
  deleteUrl,
  getMyLandlords,
  getById,
  getuniqueDataLocations,
  getAllNames,
  getAllInventory,
  getAllAmenities,
  deleteConsultantId,
  LandlordApprovalRequest,
  resendWelcomeEmail,
  getAllConsultants,
  getConsultantDetails,
  landlordReviews,
  getAllRequests,
  updateChangeRequest,
}: IuseFetchData) => {
  const { getUtilMethod } = useGetUtilMethod();
  const { postUtilMethod } = usePostUtilMethod();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalMode, setModalMode] = useState<"ADD" | "EDIT">("ADD");
  const [modalMode2, setModalMode2] = useState("Change");
  const [data, setData] = useState([]);
  const [reviewData, setReviewData] = useState<any>({});
  const [consultantsData, setConsultantsData] = useState([]);
  const [consultantsDetails, setConsultantsDetails] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectNameData, setProjectNameData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [UniqueData, setUniqueData] = useState({});
  const [current, setCurrent] = useState({});
  const history = useHistory();

  const fetchData = getAll
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAll });
      setData(res.data.items);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };
  const fetchConsultantsData = getAllConsultants
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllConsultants });
      const data = res?.data?.data
      setConsultantsData(data);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };
  const fetchConsultantsDetails = getConsultantDetails
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getConsultantDetails });
      const data = res?.data
      setConsultantsDetails(data);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };
  const fetchProjectNameData = getAllNames
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllNames });
      setProjectNameData(res.data.items);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };

  const fetchAllInventoryData = getAllInventory
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllInventory });
      setInventoryData(res?.data);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found of inventory");
    };

  const fetchAllAmenitiesData = getAllAmenities
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllAmenities });
      setAmenitiesData(res?.data?.items);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found of Amenity");
    };

  const fetchProjectsData = getAllProject
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllProject });
      setProjectData(res.data.project);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };

  const uniquefetchData = getuniqueDataLocations
    ? async () => {
      setLoading(true);

      const res = await getUtilMethod({ url: getuniqueDataLocations });
      setUniqueData(res.data.items);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };

  const fetchDataById = getById
    ? async (id: any) => {
      setLoading(true);
      const res = await getUtilMethod({ url: getById, params: { id } });
      setSingleData(res.data.item);
      setLoading(false);
    }
    : async (id: any) => {
      debugger;
      console.log("get by id url is not defined");
    };

  const createFunction = async (values: any, callback: any = () => { }) => {
    setLoading(true);
    const res: any = await postUtilMethod({ url: create, params: values });
    if (res.status === 200) {
      Swal.fire({
        title: "Profile added successfully",
        text: "",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#358c1b",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      const res = await getUtilMethod({ url: getAll });
      setData(res.data.items);
      if (res?.data?.items) {
        setData(res.data.items);
      }
      callback(res?.data?.items);
    } else if (res.status === 500) {
      // Handle the case where the email is already registered
      Swal.fire({
        title: "Error",
        text: "Email already registered",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    setOpen(false);
    setLoading(false);
  };

  // Get Landlord Review
  const fetchLandlordReviewData = async (accessToken: string) => {
    try {
      const response = await axios.get(LandlordsReview, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      setReviewData(response?.data?.review);
      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const createReviewFunction = async (values: any, callback: any = () => { }) => {
    setLoading(true);
    const res: any = await postUtilMethod({ url: createReview, params: values });
    if (res.status === 200) {
      Swal.fire({
        title: "Review added successfully",
        text: "",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#358c1b",
        confirmButtonText: "Okay",
      });
      setReviewData(res.data.review)
    } else if (res.status === 500) {
      Swal.fire({
        title: "Error",
        text: "Already reviewed",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    setOpen2(false);
    setLoading(false);
  };

  // Update Review
  const updateReviewFunction = !updateReview
    ? async () => {
      console.log(" no update url");
    }
    : async (values: any, url?: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: url ? url : updateReview,
        params: values,
      });
      if (res.status === 200) {
        Swal.fire({
          title: "Review updated successfully",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#358c1b",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        setReviewData(res.data.review)
      }
      setLoading(false);
      setOpen2(false);
    };

  const createConsultantFunction = async (values: any, callback: any = () => { }) => {
    setLoading(true);
    const res: any = await postUtilMethod({ url: create, params: values });
    if (res.status === 200) {
      Swal.fire({
        title: "Consultant Profile created successfully",
        text: "",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#358c1b",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      const res = await getUtilMethod({ url: getAllConsultants });
      setData(res.data.data);
      if (res?.data?.data) {
        setData(res.data.data);
      }
      callback(res?.data?.data);
    } else if (res.status === 500) {
      // Handle the case where the email is already registered
      Swal.fire({
        title: "Error",
        text: "Email already registered",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    setOpen(false);
    setLoading(false);
  };

  const createConsultantFunctionNew = async (values: any) => {
    const response = await axios.post(REGISTRATION_URL, values, {
      headers: {
        'content-type': 'multipart/form-data'
      },
    });
    setData(response?.data);
    return response?.data;
  }

  const createLandlordFunction = async (values: any, callback: any = () => { }) => {
    setLoading(true);
    const res: any = await postUtilMethod({ url: createLandlord, params: values });
    if (res.status === 200) {
      Swal.fire({
        title: "Profile added successfully",
        text: "",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#358c1b",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      const res = await getUtilMethod({ url: getAll });
      setData(res.data.items);
      if (res?.data?.items) {
        setData(res.data.items);
      }
      callback(res?.data?.items);
    } else if (res.status === 500) {
      // Handle the case where the email is already registered
      Swal.fire({
        title: "Error",
        text: "Email already registered",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    setOpen(false);
    setLoading(false);
  };

  const createProjectNameFunction = async (values: any, callback: any = () => { }) => {
    setLoading(true);
    const res: any = await postUtilMethod({ url: create, params: values });
    if (res.status === 205) {
      Swal.fire({
        title: "Project Name already added.",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#358c1b",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      const res = await getUtilMethod({ url: getAll });
      setData(res.data.items);
      if (res?.data?.items) {
        setData(res.data.items);
      }
      callback(res?.data?.items);
    }
    else if (res.status === 200) {
      Swal.fire({
        title: "Project Name added successfully",
        text: "",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#358c1b",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
      const res = await getUtilMethod({ url: getAll });
      setData(res.data.items);
      if (res?.data?.items) {
        setData(res.data.items);
      }
      callback(res?.data?.items);
    }
    setOpen(false);
    setLoading(false);
  };

  const updateFunction = !update
    ? async () => {
      console.log(" no update url");
    }
    : async (values: any, url?: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: url ? url : update,
        params: values,
      });
      if (res.status === 200) {
        Swal.fire({
          title: "Profile updated successfully",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#358c1b",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        const response = await getUtilMethod({ url: getAll });
        setData(response.data.items);
      }
      setLoading(false);
      setOpen(false);
    };

  // landlordImage
  const updateLandlordImageFunction = !update
    ? async () => {
      console.log(" no update url");
    }
    : async (values: any, url?: any) => {
      const res: any = await postUtilMethod({
        url: url ? url : update,
        params: values,
      });
      if (res.status === 200) {
        Swal.fire({
          title: "Profile Image updated successfully",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#358c1b",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    };

  // Update Consultant
  const updateConsultantFunction = !update
    ? async () => {
      console.log(" no update url");
    }
    : async (values: any, url?: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: url ? url : update,
        params: values,
      });
      if (res.status === 200) {
        Swal.fire({
          title: "Consultant Profile updated successfully",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#358c1b",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        fetchConsultantsData();
      }
      setLoading(false);
      setOpen(false);
    };

  // Landlord Approval Request
  // const landlordApprovalFunction = !LandlordApprovalRequest
  //   ? async () => {
  //     console.log(" no update url");
  //   }
  //   : async (id: any, url?: any) => {
  //     setLoading(true);
  //     const res: any = await postUtilMethod({
  //       url: LandlordApprovalRequest,
  //       params: { id },
  //     });
  //     if (res.status === 200) {
  //       Swal.fire({
  //         title: "Landlord Approval request Submitted successfully",
  //         text: "",
  //         icon: "success",
  //         showCancelButton: false,
  //         confirmButtonColor: "#358c1b",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "OK",
  //       });
  //       const res = await getUtilMethod({ url: getAll });
  //       setData(res?.data?.items);
  //       setCount(res?.data?.items?.length)
  //     }
  //     setLoading(false);
  //     setOpen(false);
  //   };
  const landlordApprovalFunction = async (id: string, status: 'approved' | 'rejected') => {
    setLoading(true);
    try {
      const res: any = await postUtilMethod({
        url: LandlordApprovalRequest,
        params: { id, status }, // Pass both ID and status to the API
      });

      if (res.status === 200) {
        Swal.fire({
          title: `Landlord Approval Request ${status === 'approved' ? 'Approval' : 'Rejected'} Successfully`,
          icon: "success",
          confirmButtonColor: "#358c1b",
          confirmButtonText: "OK",
        });
        const updateRes = await getUtilMethod({ url: getAll });
        setData(updateRes?.data?.items);
        setCount(updateRes?.data?.items?.length);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error appropriately
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const deleteFunction = !deleteUrl
    ? async () => {
      console.log("No delete url");
    }
    : async (id: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: deleteUrl,
        params: { id },
      });
      if (res.status === 200) {
        const res = await getUtilMethod({ url: getAll });
        setData(res.data.items);
      }
      setLoading(false);
    };
  const suspendFunction = !deleteUrl
    ? async () => {
      console.log("No delete url");
    }
    : async (id: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: deleteUrl,
        params: { id },
      });
      if (res.status === 200) {
        const res = await getUtilMethod({ url: getAllConsultants });
        const data = res?.data?.data
        setConsultantsData(data);
      }
      setLoading(false);
    };

  const deleteConsultantFunction = !deleteConsultantId
    ? async () => {
      console.log("No delete url");
    }
    : async (id: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: deleteConsultantId,
        params: { id },
      });
      if (res.status === 200) {
        const res = await getUtilMethod({ url: getAll });
        setData(res.data.items);
      }
      setLoading(false);
    };

  const resendInvitationFunction = !resendWelcomeEmail
    ? async () => {
      console.log("No delete url");
    }
    : async (id: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: resendWelcomeEmail,
        params: { id },
      });
      if (res.status === 200) {
        const res = await getUtilMethod({ url: getAll });
        setData(res.data.items);
      }
      setLoading(false);
    };

  const fetchChangeConsultantData = getAllRequests
    ? async () => {
      setLoading(true);
      const res = await getUtilMethod({ url: getAllRequests });
      setData(res.data?.data);
      setLoading(false);
    }
    : async () => {
      console.log("no get url found");
    };

  const updateChangeConsultantFunction = !updateChangeRequest
    ? async () => {
      console.log(" no update url");
    }
    : async (values: any, url?: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: url ? url : updateChangeRequest,
        params: values,
      });
      if (res.status === 200) {
        Swal.fire({
          title: "Consultant changed successfully",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#358c1b",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
        const response = await getUtilMethod({ url: getAllRequests });
        setData(response?.data?.data);
      }
      setLoading(false);
      setOpen(false);
    };

  const deleteChangeConsultantRequestFunction = !deleteUrl
    ? async () => {
      console.log("No delete url");
    }
    : async (id: any) => {
      setLoading(true);
      const res: any = await postUtilMethod({
        url: deleteUrl,
        params: { id },
      });
      if (res.status === 200) {
        const res = await getUtilMethod({ url: getAllRequests });
        setData(res.data.data);
      }
      setLoading(false);
    };

  const iconClickHandler =
    (user = "/") =>
      (data: any, type: any) => {
        if (type === "EDIT") {
          setModalMode("EDIT");
          setCurrent(data);
          setOpen(true);
          if (user) {
            history.push(`${user}/${data._id}`);
          }
        } if (type === "EDIT2") {
          setModalMode("EDIT");
          setCurrent(data);
          setOpen(true);
        } if (type === "EDIT3") {
          setModalMode("EDIT");
          setCurrent(data);
          setOpen(true);
        } else if (type === "delete") {
          Swal.fire({
            text: "",
            title: "Are you sure you want to delete this",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              deleteFunction(data._id);
              fetchData();
            }
          });
        } else if (type === "suspend") {
          Swal.fire({
            text: "",
            title: "Are you sure you want to Suspend this Account",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              suspendFunction(data._id);
              Swal.fire({
                text: "",
                title: "Account Suspended Successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#358c1b",
                confirmButtonText: "Okay"
              })
              fetchConsultantsData();
            }
          });
        } else if (type === "deleteConsultant") {
          Swal.fire({
            text: "",
            title: "Are you sure you want to remove the Landlord.",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              deleteConsultantFunction(data._id);
              fetchData();
            }
          });
        } else if (type === "requested") {
          Swal.fire({
            title: "Landlord Approval Request",
            text: "Are you sure you want to accept the request?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              landlordApprovalFunction(data._id, 'approved');
              fetchData();
            }
          });
        } else if (type === "rejected") {
          Swal.fire({
            title: "Landlord Approval Request",
            text: "Are you sure you want to Reject the request?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              landlordApprovalFunction(data._id, 'rejected');
              fetchData();
            }
          });
        } else if (type === "resend_Invitation") {
          Swal.fire({
            title: "Email Invitation!",
            text: "Are you sure you want to resend the invitation?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              resendInvitationFunction(data._id)
                .then((response: any) => {
                  if (response.status === 200) {
                    Swal.fire({
                      title: "Invitation Sent!",
                      text: "The invitation has been resent successfully.",
                      icon: "success",
                      showCancelButton: false,
                      confirmButtonColor: "#358c1b",
                    });
                    fetchData();
                  } else {
                    // Handle error cases or other statuses
                    Swal.fire({
                      title: "Error!",
                      text: "Failed to send the invitation.",
                      icon: "error",
                    });
                  }
                })
                .catch((error: any) => {
                  // Handle errors from the resendInvitationFunction call
                  Swal.fire({
                    title: "Invitation Sent!",
                    text: "The invitation has been resent successfully.",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#358c1b",
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          });

        } else if (type === "deleteChangeConsultantRequest") {
          Swal.fire({
            text: "",
            title: "Are you sure you want to delete this",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#358c1b",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((status: any) => {
            if (status.isConfirmed) {
              setLoading(true);
              deleteChangeConsultantRequestFunction(data._id);
              Swal.fire({
                text: "",
                title: "Request rejected Successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#358c1b",
                confirmButtonText: "Okay"
              })
              fetchChangeConsultantData();
            }
          });
        }
      };
  const params: any = useParams();
  useEffect(() => {
    if (
      params?.id &&
      Object.keys(singleData).length < 1 &&
      (window.location.href.indexOf("edit") != -1 ||
        window.location.href.indexOf("check") != -1)
    ) {
      fetchDataById(params?.id);
    } else if (!data?.length && !loading) {
      fetchData();
      fetchConsultantsData();
      fetchConsultantsDetails();
      fetchProjectsData();
      fetchProjectNameData();
      fetchAllInventoryData();
      fetchAllAmenitiesData();
      fetchChangeConsultantData();
    }
  }, [params?.id]);

  return {
    data,
    setData,
    reviewData,
    setReviewData,
    fetchConsultantsData,
    consultantsData,
    setConsultantsData,
    consultantsDetails,
    setConsultantsDetails,
    loading,
    setLoading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    setCount,
    open,
    setOpen,
    open2,
    setOpen2,
    modalMode,
    setModalMode,
    modalMode2,
    setModalMode2,
    createFunction,
    createReviewFunction,
    createLandlordFunction,
    createConsultantFunction,
    updateFunction,
    updateLandlordImageFunction,
    updateReviewFunction,
    updateConsultantFunction,
    createConsultantFunctionNew,
    updateChangeConsultantFunction,
    iconClickHandler,
    current,
    setCurrent,
    fetchDataById,
    uniquefetchData,
    singleData,
    setSingleData,
    setUniqueData,
    UniqueData,
    projectData,
    setProjectData,
    projectNameData,
    setProjectNameData,
    createProjectNameFunction,
    inventoryData,
    setInventoryData,
    amenitiesData,
    setAmenitiesData,
    deleteConsultantFunction,
    landlordApprovalFunction,
    fetchLandlordReviewData,
  };
};

export default useFetchData;
